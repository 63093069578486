.prestamo-completo{

   
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
}
.prestamo-cabeza{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.prestamo-centro{
    display: flex;
    flex-direction: column;
    padding-right: 218px;
}
.div1234{
    margin-top: 31px;
    border: solid 1px;
}
.prestamo-info{
    display: flex;
    width: 43%;
    flex-direction: column;
    border: solid 1px;
    border-radius: 5px;
    height: 20%;
    margin-top: 10px;
}
.info1-prestamo{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 25%;
}
.prestamo-div-esp{
    display: flex;
    width: 100%;
    height: 20%;
    border: solid 1px;
    border-bottom: none;
    padding: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-end;
}
.esp-prestamos{
    width: 80%;
    margin-top: 25px;
}
.observa{
    display: flex;
    border: solid 1px;
    width: 80%;
    margin-top: 20px;
    min-height: 80px;
    align-items: center;
    justify-content: space-around;
}
.firma{
    width: 60%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 60px;
}