

@media only screen and (max-width: 880px) {
    .padre432{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        
     }
     .hijo1{
        width: 100%;
       
        display: flex;
        flex-direction: column;
     flex-wrap: wrap;
       
        justify-content: center;
        align-items: center;
        justify-items: center;
     
     }
.hijo11{
   display: flex;

flex-direction: column;
justify-content: center;
align-items: center;
   
   width: 100%;
   
background-color: #bdbdbd;
}
.pepe{
   display: flex;
   vertical-align: middle;
   justify-items: center;
   justify-content: center;
}

.hijo12{
   display: flex;
   vertical-align: middle;
flex-direction: column;
justify-content: center;
align-items: center;
   
   width: 100%;
   background-color: #b8b7b7;


}
.espe{
   color: #ffffff;
}
a{
   text-decoration: none;
}
.hijo13{
   cursor: pointer;
   display: flex;
   
flex-direction: row;
justify-content: space-around;

   
   width: 100%;
   background-color: #b8b7b7;


}
.italic{
   
   font-style: italic;
}

.italicNO{
   
   font-weight: bold;
}


     .hijo3{
      display: flex;
      width: 100%;
      height: 60px;
      background-color: #6b705c;
      justify-content: space-around;
      vertical-align: middle;
      justify-items: center;
      align-items: center;
     }
     .hijo2{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
     }
    }
@media only screen and (max-width: 580px) {
    .padre432{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
     }
     .hijo1{
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        justify-content: center;
     }
     .hijo2{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
     }
    }
  @media only screen and (max-width: 480px) {
    .padre432{
      position:absolute;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        position: absolute;
     }
     .hijo1{
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        justify-content: center;
     }
     .hijo2{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     }
  
  }