.containers{
background-image: url(../../../asset/VDLL.jpg) !important;
position: absolute;
width: 100%;
height: 100%;
background-size: cover;
background-position: center center;
}
.input-pin{
  backdrop-filter: blur(10px);
  background-color: transparent;
  border: solid 1px;
  border-color: white;
  color: white;
  border-radius: 5px;
}
.resultado1{
  height:20px
}
.buton-pin{
  background-color:transparent;
  backdrop-filter: blur(10px);
  color: white;
  border: solid 1px;
  border-color: white;
  
}
.label-pin{
  width:108%
}
.marco{
       /* color: black; */
       width: 30%;
       height: 100%;
       position: fixed;
       top: 0%;
       left: 70%;
       background-color: rgba(0, 0, 0, 0.381);
       backdrop-filter: blur(10px);
       /* backdrop-filter: blur(1px); */
}
.boton-login-div{
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  height: 100%;
  color: white;
  flex-wrap: nowrap;
}

@media only screen and (max-width: 880px) {
    
   }
   @media only screen and (max-width: 580px) {
   
   }
@media only screen and (max-width: 480px) {
    
    
  }

  .botones22{
    
    display:flex;
    flex-direction: column;
    background-color: #ffffff36; 
   transition: 0.3s;
    width: 100%;
    height: 20%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.botones22:hover{
    background-color: #ffffff36;
}

.login{

  background-color: black;
}